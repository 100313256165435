.stream-style {
    background-color: #f3f4f5;
    border-radius: 5%;
    box-shadow: 10px 10px rgba(0, 0, 0, 0.015);
}

.webcam-screen-list {
    padding: 0.5em 3em 2em 3em;
    grid-template-columns: 90%;
    margin-right: 5%;
    margin-left: 5%;
    width: 90%;
}

.webcam-screen {
    padding: 0.5em 3em 2em 3em;
    grid-gap: 1%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    height: fit-content;
}

.webcam-screen .accordion-content {
    max-height: 100%;
}

.webcam-screen .accordion-image {
    display: none;
}

.webcam-screen .accordion-image-active {
    display: none;
}

.static-image {
    height: auto;
    width: 90%;
    aspect-ratio: 16 / 9;
}

.accordion-content {
    overflow: hidden;
    max-height: 0px;
    display: flex;
}

.accordion-content-active {
    overflow: hidden;
    max-height: 100%;
}

.accordion-button {
    display: block;
    height: 100%;
    width: 100%;
    border: none;
}

.accordion-button-active {
    background-color: #e7e7e7;
    display: block;
    height: 100%;
    width: 100%;
    border: none;
}

.accordion-image {
    transition: 0.3s;
    margin: 1px;
    transform: rotate(90deg);
    width: 10px;
    height: 10px;
}

.accordion-image-active {
    transition: 0.3s;
    margin: 1px;
    transform: rotate(180deg);
    width: 10px;
    height: 10px;
}

.go-button {
    display: inline-block;
    background-color: rgb(87, 87, 87);
    border-radius: 10px;
    color: #eeeeee;
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
    font-size: 100%;
}
.go-button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}
.go-button span:after {
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}
.go-button:hover {
    background-color: #dfdfdf;
}

.toggle-button {
    width: 10vw;
    height: 10vh;
    border-radius: 10px;
    border: none;
    background-color: #dfdfdf;
    margin: 1vh;
}

.toggle-button .list-image {
    width: 7vh;
    height: 7vh;
    filter: opacity(0.5);
}

.toggle-button-list {
    width: 10vw;
    height: 10vh;
    border-radius: 10px;
    border: none;
    background-color: #dfdfdf;
    margin: 1vh;
}

.toggle-button-list .grid-image {
    width: 7vh;
    height: 7vh;
    filter: opacity(0.5);
}

@media only screen and (max-width: 600px) {
    .toggle-button {
        background-color: darkcyan;
        align-self: left;
        width: 30vw;
        height: 10vh;
        border-radius: 10px;
        border: none;
        background-color: #dfdfdf;
        margin: 1vh;
    }
    .toggle-button-list {
        width: 30vw;
        height: 10vh;
        border-radius: 10px;
        border: none;
        background-color: #dfdfdf;
        margin: 1vh;
    }
}

.grid-image {
    width: 7vh;
    height: 7vh;
    margin-bottom: 3%;
}

.list-image {
    width: 7vh;
    height: 7vh;
    margin-bottom: 3%;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 15vh;
    padding-top: 2vh;
    text-align: center;
    font-size: smaller;
}

.paragraph {
    margin: 5%;
}

.contributer-picture {
    width: 100%;
    height: auto;
}

.contributer-bio {
    margin-top: 5%;
}

.initial-input-field {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.map-screen {
    margin-top: 1%;
    margin-left: 5%;
    margin-right: 5%;
    height: 70vh;
}

.map-screen-left {
    width: 29%;
    float: left;
    height: 100%;
    margin: 0;
    padding: 1%;
    text-align: left;
    background-color: #eeeeee;
}

.map-screen-left-mobile {
    width: 100%;
    display: inline-block;
    margin-top: 1%;
    padding: 1%;
    text-align: left;
    background-color: #eeeeee;
}

.map-screen-right {
    width: 70%;
    height: 70vh;
    float: right;
    margin: 0;
    padding: 0;
}

.map-screen-right-mobile {
    width: 100%;
    margin-bottom: 1%;
    padding: 0;
    height: 45vh;
}

.map-view {
    justify-content: "center";
    margin: 0;
    padding: 0;
    width: "100%";
    height: 100%;
    background-color: #eeeeee;
}

.webcam-label {
    display: contents;
    color: rgb(0, 25, 71);
    font-size: 100%;
}

.webcam-map-card {
    height: 100%;
}

.webcam-map-card-text {
    margin-top: 5%;
    background-color: white;
    padding-bottom: 1%;
}

.webcam-map-card-text p {
    margin: 0;
    text-align: center;
}

.webcam-map-card-left-mobile {
    width: 49%;
    float: left;
    clear: left;
    margin: 1%;
}

.webcam-map-card-right-mobile {
    width: 49%;
    margin-top: 0;
    float: right;
}

.apply-margin {
    margin: 5%;
}

.video-stream {
    display: flex;
    justify-content: center;
    aspect-ratio: 16 / 9;
    cursor: pointer;
}

.fullscreen-enabled .video-stream {
    aspect-ratio: unset;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.error-message {
    color: red;
    font-weight: bold;
}

.webcam-title {
    text-align: center;
    color: rgb(0, 25, 71);
    text-shadow: 2px 2px rgb(0, 70, 117, 0.1);
}

.large-font {
    font-size: 40px;
    text-align: center;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
}

.weather-screen-card {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    background-color: #eeeeee;
    padding: 1%;
}

.weather-screen {
    margin-top: 3%;
}

.weather-slide-container {
    width: 25%;
    margin-left: 5%;
    margin-top: 0%;
    float: left;
}

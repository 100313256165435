.App {
  text-align: center;
  margin-bottom: 15vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(0, 64, 121);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

.Nav-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  min-height: 10vh;
  background-color: rgb(0, 64, 121);
  color: rgb(0, 0, 0); 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stockText {
  text-align: center;
  max-width: 40%;
  color: rgb(0, 0, 0); 
  margin:0 auto;
}

.footer {
  width: fit-content;
  margin-top: 15vh;
}
